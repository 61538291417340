.footer-section {
  padding: get($spacings, 'xlarge') 0 0;
  background: $black;
  color: $white;

 .footer-content-container {
   position: relative;

   /* Footer Header */
   .footer-header {
     position: absolute;
     top: -55.5%;
     left: remcalc(10);
     font-size: get($fontsize, 'xhuge');
     font-weight: get($fontweight, 'bold');
     letter-spacing: 1px;
     text-shadow: remcalc(7) remcalc(7) remcalc(20) rgba($black, 0.5);
     user-select: none;
     pointer-events: none;

     @include breakpoint(small) {
       top: -73%;
       font-weight: get($fontweight, 'extrabold');
     }

     @include breakpoint(medium) {
       top: -114%;
       font-size: get($fontsize, 'mega');
     }
   }
 }

  /* Footer Link Style */
  .footer-link {
    color: inherit;
    text-decoration: none;
    transition: color .3s $ease-cubic;

    &.is-transparent {
      color: rgba($white, .5);

      &:hover { color: rgba($white, 1); }
    }
  }

  .contact-wrapper {

    /* Contact Credentials Container */
    .contact-credentials {
      margin: 0 0 get($spacings, 'large');

      @include breakpoint(medium) { font-size: get($fontsize, 'small'); }

      /* Phone */
      .contact-phone {
        letter-spacing: 1px;
        margin: 0 0 get($spacings, 'medium');

        .telephone-link {
          &:not(:last-child):after {
            display: inline-block;
            content: '|';
            margin: 0 get($spacings, 'small');
          }
        }
      }
    }

    /* Form Wraper */
    .form-wrapper {
      display: none;

      @include breakpoint(small) {
        display: block;
        // @todo
      }
    }
  }

  /* Copyright */
  .copyright {
    display: flex;
    flex-direction: column;
    padding: get($spacings, 'medium') 0;
    border-top: 1px solid rgba($white, .1);
    color: rgba($white, .5);

    @include breakpoint(small) {
      flex-direction: row;
      justify-content: space-between;
    }

    .copyright-links {
      display: flex;
      justify-content: space-between;
      margin: 0 0 get($spacings, 'medium');

      @include breakpoint(small) {
        order: 2;
        margin: 0;
      }

      .footer-link {
        &:not(:last-child) {
          @include breakpoint(small) { margin: 0 get($spacings, 'large') 0 0; }
        }
      }
    }

    .copyright-paragraph {
      max-width: remcalc(140);
      line-height: 1.33;

      @include breakpoint(small) {
        order: 1;
        max-width: 100%;
      }
    }
  }
}
