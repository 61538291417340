$DOT_SIZE: 5 !default;

.scroll-spy {
  display: none;
  position: fixed;
  top: 31vh;
  right: calc(50vw - #{remcalc($spywidth / 1.3)} - #{remcalc($content-container / 2)}); // Przybliżone do lewej
  width: remcalc($spywidth);
  color: $silver;
  z-index: get($layers, 'top');
  pointer-events: none;
  user-select: none;

  .spy-list-element {
    display: flex;
    justify-content: flex-end;

    &.is-active {
      /* Doubles the size on active state */
      .spyscroll-dot {
        width: remcalc($DOT_SIZE * 2);
        height: remcalc($DOT_SIZE * 2);
      }

      /* Changes the size of the Number */
      .spyscroll-number {
        font-size: remcalc(20);
        font-weight: get($fontweight, 'bold');
      }
    }

    .spy-list-link {
      display: flex;
      align-items: center;
      margin-bottom: remcalc(35);
      text-decoration: none;
      color: inherit;
      pointer-events: all;

      /* Dot positioning */
      &.is-home {
        width: remcalc(10);
        display: flex;
        margin-bottom: remcalc(44);
      }

      /* Displays the section name on hover */
      &:hover .spy-list-text,
      &:focus .spy-list-text { width: remcalc(125); }
    }

    /* Spyscroll Dot */
    .spyscroll-dot {
      display: inline-block;
      width: remcalc($DOT_SIZE);
      height: remcalc($DOT_SIZE);
      background: $silver;
      border-radius: 100%;
    }

    /* Spyscroll Text */
    .spy-list-text {
      $_TEXT_HEIGHT: 20 !default;

      height: remcalc($_TEXT_HEIGHT);
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      font-size: remcalc(12);
      text-align: right;
      line-height: remcalc($_TEXT_HEIGHT);
      margin-right: remcalc(10);
      transition: width 1s $ease-cubic;
    }

    /* Spyscroll Number */
    .spyscroll-number {
      font-size: remcalc(16);
      letter-spacing: 3.4px;
    }

    &:nth-child(2) {
      .spyscroll-number { letter-spacing: remcalc(5.6); }
    }

    &:last-child {
      .spyscroll-number { letter-spacing: remcalc(4.2); }
    }
  }

  /* The line element */
  .spy-list {
    position: relative;

    &:after {
      position: absolute;
      top: remcalc(-94);
      right: remcalc(-30);
      display: block;
      content: '';
      background: $silver;
      width: .1rem;
      height: remcalc(100);
    }
  }

  @include breakpoint(large) { display: block; }
}
