/* Slider Section */
.slider {
  margin-bottom: remcalc(400); //For full section visibility PROD ONLY
  padding-bottom: get($spacings, 'huge');
}

/* Main Image Container */
.hero-slider-container {
  position: relative;
  padding: 0;
  margin-bottom: get($spacings , 'medium');

  @include breakpoint(medium) {
    padding: 0 get($spacings, 'medium');
    margin-bottom: get($spacings, 'large');
  }
}

/* Main Image */
.hero-slider {
  position: relative;
  .hero-slide {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:first-child {
      position: relative;
    }

    &.is-active {
      opacity: 1;
    }

    /* Image Overlay */
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include smoothgradient($black, 'to top');

      @include breakpoint(medium) {
        left: -20%;
        width: 140%;
      }
    }

    .slide-background {
      display: block;
      height: remcalc(620);
      width: 100vw;
      object-fit: cover;

      @include breakpoint(small) { width: 100%; }
      @include breakpoint(medium) {
        position: relative;
        height: remcalc(810);
        width: 140%;
        left: -20%;
      }
    }

    .slide-content-container {
      position: absolute;
      bottom: get($spacings);
      z-index: get($layers, 'lower');

      @include breakpoint(medium) { bottom: get($spacings, 'xlarge'); }
    }
  }
}

/* Main Slide Typography */

.slide-content {
  .slide-heading {
    margin-bottom: get($spacings, 'small');
    font-size: get($fontsize, 'xlarge');
    font-weight: get($fontweight, 'bold');
    letter-spacing: 2px;
    line-height: 1.33;

    @include breakpoint(tablet) {
      max-width: remcalc(758);
      margin-bottom: get($spacings, 'large');
      font-size: get($fontsize, 'xhuge');
    }
  }

  .slide-subheading {
    margin-bottom: get($spacings, 'xlarge');
    font-size: get($fontsize, 'medium');
    font-weight: get($fontweight, 'light');
    letter-spacing: 4.5px;
    text-transform: uppercase;

    @include breakpoint(tablet) { font-size: get($fontsize, 'xlarge'); }
    @include breakpoint(medium) { margin-bottom: get($spacings, 'huge'); }
  }
}

/* Slides */
.thumbnail-slider {
  $_THUMBNAIL_WIDTH: 135 !default;
  $_THUMBNAIL_WIDTH_DESKTOP: 225 !default;

  margin: 0 0 get($spacings, 'large');
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  cursor: pointer;

  @include breakpoint(small) { margin: 0 0 get($spacings, 'large'); }
  @include breakpoint(medium) { margin: 0 0 (2 * get($spacings));  }

  .thumbnail-slide {
    position: relative;
    display: inline-block;
    width: remcalc($_THUMBNAIL_WIDTH);
    height: remcalc(100);
    border: 1px solid $black;

    @include breakpoint(medium) {
      width: remcalc($_THUMBNAIL_WIDTH_DESKTOP);
      height: remcalc(135);
    }

    /* Thumbnail Dark Overlay */
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba($black, .5);
    }

    /* Thumbnail Hover && Current Slide */
    &:hover,
    &.is-active {
      .thumbnail-slide-heading {
        opacity: 0;
        visibility: hidden;
      }

      &:after {
        background: transparent;
      }
    }

    .thumbnail-slide-link {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: $white;

      .thumbnail-slide-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .thumbnail-slide-heading {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 0 get($spacings, 'small') get($spacings, 'small');

        font-size: get($fontsize, 'small');
        font-weight: get($fontweight, 'light');
        letter-spacing: 2px;
      }
    }
  }
}

/* Horizontal Rule */
.slider-break {
  border: 0;
  height: 1px;
  width: 100%;
  background: rgba($white, .2);
  margin: 0 0 get($spacings, 'medium');

  @include breakpoint(medium) { margin: 0 0 get($spacings, 'large'); }
}
