/* Regtech Footer */
.regtech-footer {
  padding: get($spacings, 'large') 0 0 0;

  @include breakpoint(medium) {
    margin: remcalc(-48) 0 0 0;
  }

  .regtech-footer-header {
    margin: 0 0 get($spacings, 'large');

    @include breakpoint(tablet) { margin: 0 0 get($spacings, 'huge'); }
  }
}

/* Columns Wrapper */
.regtech-columns-wrapper {
  @include breakpoint(tablet) {
    display: flex;
    justify-content: space-between;
  }

  .regtech-column {
    margin: 0 0 get($spacings, 'large');
    font-size: get($fontsize);
    line-height: 1.8;

    @include breakpoint(tablet) {
      flex: 0 0 30%;
      margin: 0;
      max-width: 30%;
      font-size: get($fontsize, 'xmedium');
    }
  }
}