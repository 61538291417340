// Font Weight
$fontweight: (
  'light': 300,
  'default': 400,
  'regular': 400,
  'semibold': 500,
  'bold': 600,
  'extrabold': 800
);

// Font Size
$fontsize: (
  'micro': remcalc(9),
  'xsmall': remcalc(10),
  'small': remcalc(12),
  'default': remcalc(12),
  'xmedium': remcalc(14),
  'medium': remcalc(16),
  'xregular': remcalc(20),
  'regular': remcalc(28),
  'xlarge': remcalc(30),
  'large': remcalc(40),
  'xhuge': remcalc(50),
  'huge': remcalc(60),
  'xgiant': remcalc(70),
  'giant': remcalc(100),
  'xmega': remcalc(120),
  'mega': remcalc(150),
);

// Spacings
$spacings: (
  'default': remcalc(30),
  'small': remcalc(10),
  'medium': remcalc(20),
  'large': remcalc(40),
  'xlarge': remcalc(70),
  'huge': remcalc(90),
);

// Layers
$layers: (
  'absolute': 1000,
  'top': 900,
  'medium': 300,
  'low': 200,
  'lower': 100,
  'default': 0,
  'below': -1
);

/* Elements Size */
$content-container: 1200 !default;
$content-container-small: 1130 !default;
$content-container-padding: 20 !default;
$spywidth: 165 !default;
$spypadding: 10 !default;
$nav-height: 50 !default;
