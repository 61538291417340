html,
body {
  width: 100%;
  height: 100%;
  font-family: $primary-font;
  font-size: $font-size;
  font-weight: get($fontweight);
  background: $white;
  color: $black;
  text-size-adjust: 100%;
}

.is-menu-open { overflow: hidden; }
.site-container { position: relative; }

/* Anti Aliasing for fonts */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Custom Selectin */
::selection {
  color: $white;
  background: $lavender;
}
