/* Feature Boxes */
// Wrapper
.feature-boxes-wrapper {
  position: relative;
  padding: 0 0 get($spacings);

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    width: remcalc(200);
    height: 1px;
    background: $black;

    @include breakpoint(medium) { width: remcalc(340); }
  }

  /* Modifier */
  &.align-line-to-right {
    &:after {
      left: auto;
      right: 0;
    }
  }

  &.no-line {
    &:after { display: none; };
  }

  @include breakpoint(small) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint(tablet) { justify-content: space-around; }
  @include breakpoint(medium) { justify-content: space-between; }
}

// Boxes
.feature-box {
  margin: 0 0 get($spacings, 'large') 0;

  @include breakpoint(small) {
    flex: 0 0 45%;
    max-width: 45%;
  }

  @include breakpoint(tablet) {
    display: flex;
    flex-direction: column;
    flex: 0 0 43%;
    margin: 0 0 remcalc(70) 0;
    padding: get($spacings);
    border-radius: remcalc(5);
    box-shadow: 0 6px 30px 0 rgba($black, .1);
    transition: box-shadow .3s $ease-cubic, transform .3s $ease-cubic;
    will-change: transform, box-shadow;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 12px 40px 0 rgba($black, .18);
    }
  }

  .feature-box-title {
    position: relative;
    margin: 0 0 remcalc(34) 0;
    font-size: get($fontsize, 'regular');
    font-weight: get($fontweight, 'bold');

    &:after {
      content: '';
      position: absolute;
      bottom: remcalc(-16);
      left: 0;
      width: remcalc(100);
      height: 1px;
      background: $lavender;
      background: linear-gradient(to right, $governor-bay 0%, $lavender 100%);
      display: block;
    }
  }

  .feature-box-content {
    margin: 0 0 get($spacings, 'medium') 0;
    font-size: get($fontsize, 'small');
    line-height: 1.74;

    @include breakpoint(tablet) { font-size: get($fontsize, 'xmedium'); }
  }

  /* Reposition Button */
  .btn {
    @include breakpoint(tablet) { margin: auto 0 0 auto; }
  }

  /* Modifiers */
  &.full {
    @include breakpoint(medium) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &.half {
    @include breakpoint(medium) {
      flex: 0 0 46.5%;
      max-width: 46.5%;
    }
  }

  &.one-third {
    @include breakpoint(medium) {
      flex: 0 0 29.5%;
      max-width: 29.5%;
    }
  }

  &.two-thirds {
    @include breakpoint(medium) {
      flex: 0 0 64%;
      max-width: 64%;
    }
  }
}

/* Feature Box Footer */
.feature-box-footer {
  padding: get($spacings, 'large') 0 get($spacings, 'medium') 0;
  text-align: center;

  @include breakpoint(small) { padding: get($spacings, 'xlarge') 0 get($spacings, 'small') 0; }
  @include breakpoint(tablet) { padding: get($spacings, 'huge') 0 get($spacings, 'small') 0; }

  .feature-box-heading {
    margin: 0 0 get($spacings, 'medium');
    font-size: get($fontsize, 'large');
    font-weight: get($fontweight, 'extrabold');

    @include breakpoint(small) {
      margin: 0 0 get($spacings, 'large');
      font-size: get($fontsize, 'huge');
    }

    @include breakpoint(tablet) { font-size: get($fontsize, 'giant'); }
  }

  .feature-box-cta {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: remcalc(240);
    z-index: get($layers, 'low');

    @include breakpoint(small) { max-width: remcalc(360); }

    @include breakpoint(tablet) {
      text-align: left;
      max-width: remcalc(600);
    }

    & > p {
      margin: 0 0 get($spacings, 'large');
      text-align: left;
      font-size: get($fontsize, 'xregular');
      font-weight: get($fontweight, 'bold');
      line-height: 1.5;
      white-space: nowrap;

      @include breakpoint(small) {
        margin: 0 auto get($spacings, 'huge');
        font-size: get($fontsize, 'regular');
      }

      @include breakpoint(tablet) {
        margin: 0 0 get($spacings, 'huge');
        max-width: remcalc(450);
      }
    }

    .feature-box-line {
      display: none;
      position: absolute;
      top: 0;
      right: remcalc(152);
      width: 1px;
      height: 56vh;
      background: linear-gradient(to bottom, $governor-bay 0%, $lavender 100%);
      z-index: get($layers, 'medium');
      pointer-events: none;

      @include breakpoint(tablet) {
        display: block;
      }
    }
  }
}
