/* Navigation Elements */
.nav-logo-link {
  display: block;

  /* Logo Image */
  .nav-logo-image {
    display: block;
    width: remcalc(105);
    height: calc(#{remcalc($nav-height)} - #{remcalc(20)});

    @include breakpoint(small) { width: remcalc(145); }
    @include breakpoint(medium) { width: remcalc(240); }

    /* Logo Fill */
    .plave-logo-claim { fill: $white; }
  }
}

.language-selector {
  display: none;

  @include breakpoint(small) {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 remcalc(8);
  }

  @include breakpoint(medium) { margin: 0 0 remcalc(16); }

  .language-select {
    &:not(:last-child) { margin: 0 remcalc(8) 0 0; }

    & > a {
      color: $white;
      text-decoration: none;
      font-size: remcalc(12);
    }

    &.is-active { font-weight: get($fontweight, 'bold'); }
  }
}

.nav-main-menu {
  /* Desktop Styles */
  @include breakpoint(small) {
    display: flex;
    justify-content: flex-end;
  }

  .nav-menu-item {
    &:not(:last-of-type){
      margin: 0 remcalc(24) 0 0;

      @include breakpoint(medium) { margin: 0 remcalc(48) 0 0; }
    }

    & > a {
      position: relative;
      color: $white;
      font-size: remcalc(14);
      font-weight: get($fontweight, 'light');
      letter-spacing: remcalc(2);
      text-decoration: none;

      &:before {
        position: absolute;
        content: '';
        display: block;
        bottom: remcalc(-4);
        left: 0;
        width: remcalc(1);
        height: 1px;
        opacity: 0;
        transition: opacity .1s $ease-cubic, width .4s $ease-cubic;

        @include breakpoint(small) { background: $monza; }
      }
    }

    &.is-active,
    &:hover {
      & > a {
        &:before {
          opacity: 1;
          width: remcalc(15)
        }
      }
    }
  }
}

.burger-menu {
  $_burger_width: 24 !default;
  $_burger_height: 24 !default;

  position: relative;
  width: remcalc($_burger_width);
  height: remcalc($_burger_height);
  margin: 0 0 0 auto;
  cursor: pointer;
  transition: transform .3s $ease-cubic;

  @include breakpoint(small) { display: none; }

  .line {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: $white;
    transition: width .4s $ease-cubic, transform .3s $ease-cubic;

    &:first-child {
      top: 30%;
      transform-origin: right top;
    }

    &:last-child {
      width: 60%;
      top: 70%;
      transform-origin: left top;
    }
  }
}

/* Nav Wrapper */
.nav-main-menu-wrapper {
  position: absolute;
  top: calc(#{remcalc($nav-height)} - #{remcalc(2)});
  left: remcalc(- $content-container-padding);
  width: 100vw;
  height: 1vh;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
  z-index: get($layers, 'top');
  transition: opacity .8s $ease-cubic, height .8s $ease-cubic;
  user-select: none;

  @include breakpoint(small) {
    position: static;
    flex: 1 1 100%;
    justify-content: flex-end;
    padding: remcalc(4) 0;
    width: auto;
    height: auto;
    background: transparent;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    overflow: visible;
  }
}

.nav-section {
  position: fixed;
  top: 0;
  left: 0;
  background: $black;
  z-index: get($layers, 'absolute');
  transition: transform .8s $ease-cubic, opacity .8s $ease-cubic;

  @include breakpoint(small) {
    padding: remcalc(10) 0;
  }

  .nav-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: remcalc($nav-height);

    @include breakpoint(small) { height: auto; }
    @include breakpoint(medium) { padding: get($spacings, small) 0; }
  }

  /* Active Mobile Menu */
  &.is-active {
    .nav-main-menu-wrapper {
      height: 100vh;
      visibility: visible;
      opacity: 1;
      background: $black;
      pointer-events: all;
      transition: opacity .8s $ease-cubic, height .8s $ease-cubic;
    }

    .nav-main-menu {
      margin: remcalc(40);
      padding: remcalc(40) remcalc(16) 0 0;
      border-right: 1px solid $white;

      .nav-menu-item {
        display: block;
        margin: 0 0 remcalc(16);
        text-align: right;

        & > a {
          display: block;
          width: 100%;
          height: remcalc(24);
          color: $white;
          font-size: remcalc(14);
          line-height: remcalc(24);
          text-decoration: none;
        }
      }
    }

    .burger-menu {
      .line {
        &:first-child { transform: rotate(-45deg) translate(-2px, -7px); }

        &:last-child {
          top: 0;
          width: 100%;
          transform: rotate(45deg) translate(3px, 2px);
        }
      }
    }
  }

  /* Scroll Feature */
  &.is-not-visible {
    @include breakpoint(small) {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  &.is-fixed {
    @include breakpoint(small) {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/* Light Theme Nav */
.is-light {
  @include breakpoint(small) {
    background: $white;
    color: $black;
  }

  .nav-logo-link {
    .nav-logo-image {
      .plave-logo-claim {
        @include breakpoint(small) { fill: $black; }
      }
    }
  }

  .language-selector {
    .language-select {
      & > a { color: inherit; }
    }
  }

  .nav-main-menu {
    .nav-menu-item {
      & > a {
        @include breakpoint(small) {
          color: inherit;
          font-weight: get($fontweight, 'bold');
        }
      }
    }
  }
}
