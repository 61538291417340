@mixin breakpoint($point) {
  @if $point == small {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  } @else if $point == medium {
    @media only screen and (min-width: 1300px) {
      @content;
    }
  } @else if $point == large {
    @media only screen and (min-width: 1560px) {
      @content;
    }
  }
}

@mixin smoothgradient($startColor, $direction: 'to bottom') {
  $scrimCoordinates: (
    0: 1,
    19: .738,
    34: .541,
    47: .382,
    56.5: .278,
    65: .194,
    73: .126,
    80.2: .075,
    86.1: .042,
    91: .021,
    95.2: .008,
    98.2: .002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);
}
