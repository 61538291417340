$_MOBILE_PADDING: 7.5vh;
$_TABLET_PADDING_TOP: 5vh;
$_TABLET_PADDING_BOTTOM: 1vh;
$_DESKTOP_PADDING_TOP: 11vh;
$_DESKTOP_PADDING_BOTTOM: 6.5vh;

.partner-section {
  padding: $_MOBILE_PADDING 0 0;

  @include breakpoint(small) { padding: $_TABLET_PADDING_TOP 0 $_TABLET_PADDING_BOTTOM; }
  @include breakpoint(tablet) { padding: $_DESKTOP_PADDING_TOP 0 $_DESKTOP_PADDING_BOTTOM; }
  @include breakpoint(medium) { padding: $_DESKTOP_PADDING_TOP 0 $_DESKTOP_PADDING_BOTTOM; }

  .partner-container {
    position: relative;

    @include breakpoint(small) { display: flex; }
  }

  /* Partner content */
  .partner-content {
    @include breakpoint(small) {
      position: relative;
      flex: 0 0 50%;
      z-index: get($layers, 'top');
    }

    /* Header */
    .partner-header {
      margin-bottom: get($spacings, 'small');
      font-size: get($fontsize, 'xlarge');
      font-weight: get($fontweight, 'extrabold');
      line-height: 1.25;
      letter-spacing: .1rem;

      @include breakpoint(medium) {
        font-size: get($fontsize, 'huge');
        line-height: 1.14;
        letter-spacing: .2rem;
      }
    }

    /* Partner Subheader */
    .partner-subheader {
      margin-bottom: get($spacings, 'large');
      font-size: get($fontsize, 'medium');
      letter-spacing: .2rem;

      @include breakpoint(medium) {
        margin-bottom: get($spacings, 'xlarge');
        font-size: get($fontsize, 'xlarge');
        font-weight: get($fontweight, 'light');
      }
    }

    /* Partner Text */
    .partner-paragraph {
      font-size: get($fontsize, 'small');
      font-weight: get($fontweight, 'light');
      letter-spacing: .5px;
      line-height: 1.7;

      @include breakpoint(small) { padding-bottom: get($spacings); }

      @include breakpoint(medium) {
        margin-bottom: get($spacings, 'xlarge');
        font-size: get($fontsize, 'xmedium');
      }
    }

    /* LinkedIn Link */
    .partner-linkedin-link {
      position: absolute;
      right: get($spacings);
      bottom: get($spacings);

      @include breakpoint(small) { position: static; }

      & > svg {
        display: block;
        fill: $silver;
        transition: fill .5s $ease-cubic;

        &:hover { fill: $denim; }
      }
    }
  }

  /* Partner Image */
  .partner-image-container {
    margin: 0 remcalc(-$content-container-padding);

    @include breakpoint(small) {
      position: absolute;
      bottom: -($_TABLET_PADDING_BOTTOM);
      right: 0;
      margin: 0 auto;
    }

    @include breakpoint(tablet) {
      bottom: -($_DESKTOP_PADDING_BOTTOM);
      right: remcalc(-40);
    }

    @include breakpoint(medium) { right: remcalc(-150); }

    .partner-image {
      display: block;
      width: 100%;

      @include breakpoint(small) { max-width: remcalc(380); }
      @include breakpoint(tablet) { max-width: remcalc(560); }
      @include breakpoint(medium) { max-width: remcalc(800); }
    }
  }

  /* Is Left Partner Image Position */
  &.is-aligned-left {

    .partner-content { margin: 0 0 0 auto; }
    .partner-linkedin-link { float: right; }

    .partner-image-container {
      @include breakpoint(small) {
        right: auto;
        left: -(get($spacings));
      }

      @include breakpoint(medium) { left: remcalc(-100); }

      .partner-image {
        @include breakpoint(small) { max-width: remcalc(460); }
        @include breakpoint(tablet) { max-width: remcalc(530); }
        @include breakpoint(medium) { max-width: remcalc(770); }
      }
    }
  }
}
