/* Variables */
$_SCROLL_TOP_SIZE: 35 !default;
$_SCROLL_TOP_OFFSET: 100 !default;

/* Home Header */
.header-home-section {
  background: #000 url('../img/header-sm.jpg') no-repeat;
  background-size: 160%;
  background-position: center;

  @include breakpoint(small) {
    background-image: url('../img/header-md.jpg');
    background-size: cover;
  }

  @include breakpoint(medium) { background-image: url('../img/header-lg.jpg'); }
  @include breakpoint(large) { background-size: remcalc(1400); }
}

.site-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .site-header-claim {
    position: relative;
    color: $white;
    letter-spacing: remcalc(2);
    font-size: get($fontsize, 'large');
    font-weight: get($fontweight, 'extrabold');

    @include breakpoint(small) { font-size: get($fontsize, 'huge'); }
    @include breakpoint(medium) { font-size: get($fontsize, 'xmega'); }
  }

  .scroll-down {
    position: absolute;
    top: remcalc($_SCROLL_TOP_OFFSET);
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: remcalc($_SCROLL_TOP_SIZE);
    height: remcalc($_SCROLL_TOP_SIZE);
    background: $white;
    border-radius: 100%;
    text-indent: -9999px;
    transform: translateX(-50%);
    transition: transform .3s $ease-cubic;

    @include breakpoint(small) {
      width: remcalc($_SCROLL_TOP_SIZE * 1.45);
      height: remcalc($_SCROLL_TOP_SIZE * 1.45);
      top: remcalc($_SCROLL_TOP_OFFSET * 1.45);
    }

    @include breakpoint(medium) {
      top: remcalc($_SCROLL_TOP_OFFSET * 2.5);

      &:hover { transform: translateX(-50%) scale(1.1); }
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      animation: pulse 3s infinite;
    }

    & > svg {
      display: block;
      width: remcalc($_SCROLL_TOP_SIZE / 2);
      height: remcalc($_SCROLL_TOP_SIZE / 2);
    }
  }
}
