.cookies {
  position: fixed;
  bottom: 0;
  display: none;
  width: 100%;
  background: $white;
  z-index: get($layers, 'absolute');

  &.is-shown {
    display: block;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: remcalc(73);
    padding: 0 remcalc(30);

    @include breakpoint(small) {
      justify-content: center;
    }

    .cookies-text {
      flex: 0 1 75%;
      font-weight: get($fontweight, 'light');
      font-size: get($fontsize, 'micro');

      @include breakpoint(small) {
        max-width: remcalc(648);
        font-size: get($fontsize);
        line-height: 1.3;
      }
    }

    .cookies-close {
      display: block;

      @include breakpoint(small) {
        margin-left: remcalc(140);
      }

      & > svg {
        display: block;
        height: remcalc(15);
        width: remcalc(15);
      }
    }
  }
}
