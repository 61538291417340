/* Colors */
$black: #000 !default;
$white: #fff !default;
$wild-sand: #f5f5f5 !default;

$silver: #b9b9b9 !default;
$denim: #187fb8 !default;
$monza: #d0021b !default;

$governor-bay: #3023ae !default;
$lavender: #c86dd7 !default;

/* Named Colors */
$gradient-dark: $governor-bay !default;
$gradient-light: $lavender !default;
$linkedin: $denim !default;

$light-shadow: rgba($black, .1) !default;
