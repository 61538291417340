.map-section {
  padding: get($spacings, 'large') 0 0;

  @include breakpoint(medium) { padding: get($spacings, 'xlarge') 0 0; }

  .map-footer-header {
    margin: 0 0 get($spacings, 'large') ;

    @include breakpoint(small) { margin: 0 0 get($spacings, 'xlarge'); }
    @include breakpoint(medium) { margin: 0 0 get($spacings, 'huge'); }
  }
}
