/* Local Variables */
$_SM_BTN_HEIGHT: 30 !default;
$_SM_BTN_RADIUS: 25 !default;
$_MD_BTN_HEIGHT: 50 !default;
$_MD_BTN_RADIUS: 35 !default;
$_ARROW_WIDTH: 36 !default;
$_LINE_WIDTH: 100 !default;


/* Buttons */
.btn {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: $black;
  font-size: get($fontsize);
  font-weight: get($fontweight, 'semibold');
  letter-spacing: .5px;
  border: 1px solid $black;
  text-decoration: none;
  user-select: none;

  /* Button Elements */
  .btn-arrow {
    width: 0;
    overflow: hidden;
    text-align: right;
    transition: width .4s $ease-cubic;
  }

  /* Disable text-decoration on hover and show arrow for every button */
  &:hover {
    text-decoration: none;

    .btn-text { margin: 0 remcalc(4) 0 0; }
    .btn-arrow { width: remcalc($_ARROW_WIDTH); }
  }

  /* Sizes */
  &.small {
    max-width: remcalc(120);
    height: remcalc($_SM_BTN_HEIGHT);
    border-radius: remcalc($_SM_BTN_RADIUS);
  }

  &.medium {
    max-width: remcalc(148);
    height: remcalc($_MD_BTN_HEIGHT);
    border-radius: remcalc($_MD_BTN_RADIUS);
  }

  /* Colors */
  &.bright {
    color: $white;
    border: 1px solid $white;
  }

  /* Types */
  &.colored {
    transition: background .4s $ease-cubic, border .2s $ease-cubic, color .3s $ease-cubic;

    &:hover {
      color: $white;
      background: $lavender;
      background: linear-gradient(to right, $governor-bay 0%, $lavender 100%);
      border: 0;
    }
  }
}

/* Show All */
.button-wrapper {
  display: flex;

  &.is-aligned-right {
    justify-content: flex-end;

    @include breakpoint(medium) {
      margin-right: 5%;
    }
  }

  .show-all {
    position: relative;
    display: inline-block;
    padding: get($spacings, 'small') 0;
    text-decoration: none;
    font-size: get($fontsize);
    letter-spacing: .5px;
    margin: 0 0 0 auto;

    &:before {
      position: absolute;
      bottom: 0;
      left: calc(-#{$_LINE_WIDTH} + 15px);
      display: block;
      content: '';
      width: calc(1% + #{remcalc($_LINE_WIDTH)});
      height: 1px;
      transition: width .5s $ease-cubic;
    }

    &:hover {
      &:before {
        width: 200%;
      }
    }

    &.is-bright {
      color: $white;

      &:before { background: $white; }
    }

    &.is-dark {
      color: $black;

      &:before { background: $black; }
    }
  }
}
