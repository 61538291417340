/* Scroll Top Pulse */
@keyframes pulse {
  0% {
    border: .5px solid transparent;
    opacity: 1;
  }

  20% {
    border: .5px solid $white;
    opacity: .6;
  }

  40% { opacity: 0; }
  100% {
    transform: scale(2.3);
    opacity: 0;
  }
}

/* Fading Animation */
@keyframes fade {
  from { opacity: .4; }
  to { opacity: 1; }
}
