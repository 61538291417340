.section {
  width: 100%;
  max-width: 100vw;

  &:not(.no-overflow) { overflow: hidden; }

  &.regular-padding {
    padding: get($spacings, 'large') 0;

    @include breakpoint(small) { padding: get($spacings, 'huge') 0; }
  }

  &.is-dark {
    background: $black;
    color: $white;
  }
}
