.hero {
  position: relative;
  padding-bottom: get($spacings, 'medium');
  color: $white;

  @include breakpoint(small) {
    padding-bottom: get($spacings, 'large');
  }

  @include breakpoint(medium) {
    padding-bottom: get($spacings, 'xlarge');
  }

  /* Content Container */
  .hero-content{
    .hero-header {
      max-width: remcalc(300);
      width: 100%;
      font-size: get($fontsize, 'xlarge');
      font-weight: get($fontweight, 'bold');
      letter-spacing: 1px;
      line-height: 1.33;
      margin-bottom: remcalc(94);

      @include breakpoint(small) {
        font-size: get($fontsize, 'large');
      }

      @include breakpoint(medium) {
        max-width: remcalc(480);
        font-size: get($fontsize, 'huge');
        font-weight: get($fontweight, 'extrabold');
        margin-bottom: remcalc(130);
      }
    }

    .hero-paragraph {
      width: 100%;
      font-size: remcalc(16);
      font-weight: get($fontweight, 'light');
      line-height: 1.6;

      @include breakpoint(small) {
        max-width: 65%;
      }

      @include breakpoint(medium) {
        line-height: 1.8;
        font-weight: get($fontweight, 'default');
      }
    }
  }

  /* Background styling for each Hero */
  // Licence Section
  &.is-licence {
    padding-top: 28vh;
    background: url('../img/section-licence-sm.jpg') no-repeat center center;
    background-size: cover;

    @include breakpoint(small) {
      background: url('../img/section-licence-md.jpg') no-repeat center center;
      background-size: cover;
    }

    @include breakpoint(medium) {
      padding-top: 37vh;
      background: url('../img/section-licence-lg.jpg') no-repeat center center;
      background-size: cover;
    }
  }

  // RegTech Section
  &.is-regtech {
    padding-top: 34vh;
    background: url('../img/section-regtech-sm.jpg') no-repeat center center;
    background-size: cover;

    @include breakpoint(small) {
      background: url('../img/section-regtech-md.jpg') no-repeat center center;
      background-size: cover;
    }

    @include breakpoint(medium) {
      padding-top: 44vh;
      background: url('../img/section-regtech-lg.jpg') no-repeat center center;
      background-size: cover;
    }
  }

  // Areas of activity Section
  &.is-areas {
    padding-top: 10.5vh;
    background: url('../img/section-areas-sm.jpg') no-repeat left center;
    background-size: cover;

    @include breakpoint(small) {
      background: url('../img/section-areas-md.jpg') no-repeat left 46%;
      background-size: cover;
    }

    @include breakpoint(medium) {
      padding-top: 33vh;
      background: url('../img/section-areas-lg.jpg') no-repeat left 78%;
      background-size: cover;
    }
  }
}
