.container {
  margin: 0 auto;
  padding: 0 remcalc($content-container-padding);
  width: 100%;
  max-width: remcalc($content-container);
}

.reduced-content {
  width: 100%;

  @include breakpoint(medium) { max-width: remcalc($content-container-small); }
}
