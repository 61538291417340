/* Utils */
.cf {
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

/* Headings */
.h-28 {
  font-size: get($fontsize, 'regular');
  font-weight: get($fontweight, 'bold');
}

.h-white { color: $white; }
